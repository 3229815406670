.header-s1 {
  .header-container {
    .header-s1__top {
      .logo {
        img {
          width: 85px;
        }
      }
    }
  }
}
