.header-s3 {
  .header-container {
    .logo {
      img {
        width: 85px;
      }
    }

    .header-s3__bottom {
      .p-right {
        margin-top: 10px;
      }
    }

    .header-s1__top {
      background-color: red;
      .logo {
        img {
          width: 85px;
        }
      }
    }
  }
}

.mobile-header {
  .logo {
    img {
      height: 80px;
    }
  }
}

.contact-form-area {
  .contact-map {
    padding: 34px;
    @media screen and (max-width: $MobileWidthScreen) {
      padding: 10px;
    }

    .iframe-map {
      border: 4px solid #fff;
      border-radius: 4px;
      width: 100%;
    }
  }
  .contact-info {
    .c-info-box {
      .icon {
        img {
          max-width: fit-content;
        }
      }
    }
  }
}

.about-s7 {
  .about-s7__thumb {
    .p-left {
      img {
        margin-bottom: 30px;
      }
      img:last-child {
        margin-bottom: 0;
      }
    }
    .p-right {
      img {
        height: 100%;
      }
    }
  }
}

.footer-s1{
  .f-widget{
    .logo{
      img {
        width: 100px;
      }
    }
  }
}