.payment-info {
  .payment-process {
    .pp-title {
      color: blue;
    }
  }

  hr {
    margin: 30px 0;
  }

  div.text {
    display: inline;
  }

  div.value {
    display: inline;

    .icon {
      background: unset;
      width: 12px;
      margin-left: 4px;
      cursor: pointer;

      svg {
        fill: #757575;
      }
    }
  }
}
