.UserDetail {
    .box {
        background: $dark;
        border-radius: $borderRadius;

        .head {
            padding: 15px;
            color: $white;
            font-size: 1.3em;
        }

        table {
            width: 100%;

            tbody {
                tr {
                    td {
                        padding: 10px 15px;
                        border-bottom: 1px solid $offDark;
                    }

                    &:last-child {
                        td {
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
