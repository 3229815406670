@import "../module.variables.scss";

.UserWraper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: radial-gradient(
    farthest-side ellipse at 10% 0,
    #333867 20%,
    #17193b
  );
  overflow: auto;
  position: relative;

  .PageFranchiseTree {
    .text-right {
      text-align: right;
    }
  }
  .text-right {
    text-align: right;
  }

  > .loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      * {
        fill: $primary;
      }
    }

    #icon {
      width: 50px;
      height: 50px;
      animation: heartbeat 1805ms ease 0s infinite normal none running;
    }

    #ripple1,
    #ripple2 {
      position: absolute;
      z-index: -100;
      animation: pulse 1805ms infinite ease-out, filter 1805ms infinite ease-out;

      * {
        fill: rgba($color: $primary, $alpha: 0.2);
      }
    }

    #ripple2 {
      z-index: -10;
      animation-delay: 0.2s;
    }
  }
}

@keyframes heartbeat {
  0% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  5% {
    transform: scale3d(1.5, 1.5, 1);
    opacity: 0.8;
  }

  12% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  16% {
    transform: scale3d(1.8, 1.8, 1);
    opacity: 0.4;
  }

  50% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  100% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }

  80% {
    transform: scale3d(9.6, 9.6, 1);
  }

  100% {
    transform: scale3d(12, 12, 1);
  }
}

@keyframes filter {
  0% {
    opacity: 0.3;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.iframe {
  display: none;
}
