@import "./module.variables.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

@import "./modules/styles";
@import "./modules.custom.scss";

@import "./pages";
@import "./components";

* {
  margin: 0;
  padding: 0;
  outline: none;
}

// html,
// body {
//   font-family: "Nunito", sans-serif;
//   font-size: 14px;
//   line-height: 22px;
//   color: $grey;
//   width: 100%;
//   height: 100%;
//   background: $secondary-background-color;
//   overflow-x: hidden;
//   overflow-y: auto;
// }

#root {
  min-height: 100%;
}

// ============================ Input style ================================
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent;
  // box-shadow: unset !important;
  -webkit-text-fill-color: #ffffff;
  caret-color: #ffffff !important;
  transition: background-color 5000s ease-in-out 0s;
  font-size: 1em;
  -moz-box-shadow: 0 0 0 50px #1a2138 inset !important;
  -webkit-box-shadow: 0 0 0 50px #1a2138 inset;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
  caret-color: #ffffff !important;
}
// ============================ End Input style ============================

// ============================ Link style ================================
// a,
// .link {
//   color: $info;
//   font-weight: 500;
//   cursor: pointer;

//   &:hover {
//     text-decoration: underline;
//   }
// }
// ============================ End Link style ============================

// ============================ Element style ================================
img {
  max-width: 100%;
}
// ============================ End Element style ============================

// ============================ Layout style ================================
// .user-row {
// 	> div {
// 		position: unset;
// 	}
// }

.textRight {
  text-align: right !important;
}

.textLeft {
  text-align: left !important;
}

.textBold {
  font-weight: 600;
  color: $dark;
}

@for $i from 50 to 200 {
  .w#{$i} {
    width: $i + unquote("px");
  }
}

@for $i from 0 to 100 {
  .mb#{$i} {
    margin-bottom: $i + unquote("px") !important;
  }
}

@for $i from 0 to 100 {
  .mt#{$i} {
    margin-top: $i + unquote("px") !important;
  }
}

@for $i from 0 to 100 {
  .mr#{$i} {
    margin-right: $i + unquote("px") !important;
  }
}

@for $i from 0 to 100 {
  .ml#{$i} {
    margin-left: $i + unquote("px") !important;
  }
}

@for $i from 0 to 100 {
  .pdb#{$i} {
    padding-bottom: $i + unquote("px") !important;
  }
}

@for $i from 0 to 100 {
  .pdt#{$i} {
    padding-top: $i + unquote("px") !important;
  }
}

@for $i from 0 to 100 {
  .pdr#{$i} {
    padding-right: $i + unquote("px") !important;
  }
}

@for $i from 0 to 100 {
  .pdl#{$i} {
    padding-left: $i + unquote("px") !important;
  }
}

@for $i from 0 to 100 {
  .pd#{$i} {
    padding: $i + unquote("px") !important;
  }
}

.wfull {
  width: 100%;
}

.sticky {
  position: sticky;

  &.top30 {
    top: 30px;
  }
}
// ============================ End Layout style ============================

// ============================ Scrollbar style ================================
* {
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: $input-background-color;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $input-border-color;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    &:hover {
      background: #0b1b3f;
    }
  }
}
// ============================ End Scrollbar style ============================

// ============================ Typograrphy style ================================
.textDanger {
  color: $danger !important;
}

.textSuccess {
  color: $success !important;
}

.textWarning {
  color: $warning !important;
}

.textInfo {
  color: $info !important;
}

.textGrey {
  color: $grey !important;
}

.textWhite {
  color: $white !important;
}
// ============================ End Typograrphy style ============================

// // ============================ Page Head style ================================
// .PageHead {
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	padding: 15px 0;
// 	margin-bottom: 10px;

// 	.title {
// 		font-size: 1.4em;
// 		display: flex;
// 		align-items: center;

// 		svg {
// 			height: 22px;
// 			margin-right: 7px;

// 			* {
// 				fill: $dark;
// 			}
// 		}
// 	}
// }
// // ============================ End Page Head style ============================

// ============================ Circle style ================================
.circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 7px;

  &.success {
    background: $success;
  }
}
// ============================ End Circle style ============================

// ============================ SPINNER STYLE ================================
svg.spinner {
  & .path {
    stroke: $grey;
  }
}
// ============================ End SPINNER STYLE ============================

// ============================ Form Note style ================================
.formNote {
  color: $white;
  margin-bottom: 10px;

  svg {
    height: 17px;
    margin-bottom: -3px;
    margin-right: 7px;

    * {
      fill: $white;
    }
  }
}
// ============================ End Form Note style ============================

// ============================ Link Affiliation style ================================
.linkAffiliation {
  border-bottom: 1px solid $offDark;
  margin-bottom: 20px;
  padding-bottom: 20px;

  .label {
    margin-bottom: 10px;
    font-size: 0.9em;
    color: $white;
  }

  .value {
    display: flex;
    align-items: center;
    color: $white;
    min-height: 44px;
    background: rgba($color: $white, $alpha: 0.1);
    padding: 10px;
    border-radius: $borderRadius;
    cursor: pointer;

    p {
      flex: 1;
      width: 100%;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;

      svg {
        height: 15px;

        * {
          fill: $white;
        }
      }
    }
  }
}
// ============================ End Link Affiliation style ============================

// ============================ Recaptcha style ================================
.grecaptcha-badge {
  visibility: hidden;
}
// ============================ End Recaptcha style ============================

// ============================ Table style ================================
.hideTableHead {
  thead {
    display: none;
  }
}

.rowInfo {
  display: flex;
  justify-content: space-between;
}

.Table {
  // border: 1px solid $input-border-color;
  padding-left: 32px;
  padding-right: 32px;
  background: rgba(0, 0, 0, 0.24);
  border-radius: $borderRadius;
  border-radius: 5px;
  box-shadow: 0 25px 20px -20px rgba(0, 0, 0, 0.1), 0 0 15px rgba(0, 0, 0, 0.06);

  &.hasSUM {
    &__Head {
      th {
        background: rgba($color: $grey, $alpha: 0.2) !important;
        color: $grey;
      }
    }

    thead.Table__Head {
      th {
        background: rgba($color: $primary, $alpha: 1) !important;
        color: $dark;
      }
    }

    tbody {
      tr:first-child {
        td {
          color: $info;
          background: rgba($color: $info, $alpha: 0.1);
        }
      }
    }
  }

  &.hideFooter {
    .Footer {
      display: none !important;
    }
  }

  &.fixed {
    table {
      table-layout: fixed !important;
    }
  }

  &.overload {
    overflow-x: auto;

    table {
      width: 100%;

      th {
        width: max-content;
        white-space: nowrap;
      }
    }
  }

  th,
  td {
    &.textSuccess {
      background: rgba($color: $success, $alpha: 0.05);
    }

    &.textDanger {
      background: rgba($color: $danger, $alpha: 0.05);
    }
    &.textWarning {
      background: rgba($color: $warning, $alpha: 0.05);
    }
  }
}
// ============================ End Table style ============================

// ============================ Page Background Map style ================================
.pageBackgroundMap {
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 30px;
  width: 1300px;
  max-width: 90%;
  opacity: 0.02;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
// ============================ End Page Background Map style ============================

// ============================ Number Report Card style ================================
.NumberReportCard {
  border-radius: $borderRadius;
  background: $dark;
  padding: 25px;
  margin-bottom: 15px;

  .label {
    font-size: 1.2em;
    margin-bottom: 15px;
  }

  .value {
    font-size: 2.5em;
    font-weight: 300;

    span.profitType {
      border: none;
      padding: 0;
    }
  }
}
// ============================ End Number Report Card style ============================

// ============================ Profit Type style ================================
.profitType {
  color: $white;
  padding: 5px;
  border-radius: 2px;
  border: 1px solid $offDark;

  &.win {
    color: $primary;
    border-color: rgba($color: $primary, $alpha: 0.2);
  }

  &.lose {
    color: $danger;
    border-color: rgba($color: $danger, $alpha: 0.2);
  }
}
// ============================ End Profit Type style ============================

// ============================ Page Title style ================================
.pageTitle {
  margin-bottom: 20px;
  font-size: 1.2em;
  border-left: 4px solid $primary;
  color: $primary;
  padding-left: 15px;
  text-transform: uppercase;
}
// ============================ End Page Title style ============================

// ============================ Box style ================================
section.box {
  border-radius: $borderRadius;
  background: $dark;

  .title {
    color: $white;
    font-size: 1.1em;
    margin-bottom: 10px;
    padding: 15px;
  }
}
// ============================ End Box style ============================

// ============================ TabLinks style ================================
.TabLinks {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba($color: $grey, $alpha: 0.2);
  margin-bottom: 20px;

  a {
    display: inline-flex;
    align-items: center;
    padding: 10px 25px;
    color: $grey;
    text-decoration: none;
    border-bottom: 2px solid rgba($color: $primary, $alpha: 0);
    font-weight: 500;

    svg {
      height: 17px;
      margin-right: 7px;

      * {
        fill: $grey;
      }
    }

    &.active {
      color: #4680ff;
      border-bottom-color: #4680ff;

      svg * {
        fill: #4680ff;
      }
    }
  }
}
// ============================ End TabLinks style ============================

// ============================ Box style ================================
.Box {
  padding: 15px;
  background: $dark;
}
// ============================ End Box style ============================

.zalo-chat-widget{
  bottom: 20px !important;
  right: 20px !important;
//   transform: none;
//   animation: pulsingBorderS2 1.5s linear 0s infinite;
//   border-radius: 50%;
}