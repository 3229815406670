@import "../../../module.variables";

.InputSelect {
    width: 100%;
    border: 1px solid rgba(70, 128, 255, 0.5);
    outline: none;
    border-radius: 5px;

    .InputSelect__control {
        border: none !important;
        box-shadow: none !important;
        height: $inputHeight;

        height: 38px;
        background: #13213E;

        font-size: 1em;
        font-weight: 400;
        padding: 0px 10px;
        width: 100%;
        flex: 1 1;
        color: #fff;
    }

    .InputSelect__option {
        cursor: pointer;

        &:hover {
            background: rgba($color: $primary, $alpha: 0.2);
            
        }
    }

    .InputSelect__option--is-selected {
        background: $primary;
        cursor: default;

        &:hover {
            background: $primary;
        }
    }

    .InputSelect__single-value {
        color: #fff;
    }
}
